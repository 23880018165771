export default {
  useCustomProperties: true,
  colors: {
    text: "#141414",
    background: "#FFF",
    primary: "#000FF0",
    gray: "#888",
    slate: "#D6D6D6",
    modes: {
      dark: {
        text: "#FFF",
        background: "#141414",
        primary: "#000FF0",
        gray: "#888",
        slate: "#D6D6D6",
      },
    },
  },
  fonts: {
    heading: `"Space Mono", sans-serif`,
    body: `"GT America", sans-serif`,
    mono: `"Space Mono", sans-serif`,
  },
  fontWeights: {
    body: 400,
    heading: 700,
    mono: 400,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.2,
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      mt: 4,
      mb: 0,
    },
    body: {
      fontFamily: "body",
      lineHeight: "heading",
      fontWeight: "body",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: 4,
    },
    h1: {
      variant: "text.heading",
      fontSize: [5, 5, 6],
    },
    h2: {
      variant: "text.heading",
      fontSize: [5, 5, 6],
    },
    h3: {
      variant: "text.body",
      fontSize: [3, 4, 4],
      fontWeight: "heading",
      mt: 2,
      mb: 2,
    },
    h4: {
      variant: "text.heading",
      fontSize: [3, 4, 4],
    },
    h5: {
      variant: "text.heading",
      fontSize: [3, 3, 3],
    },
    h6: {
      variant: "text.heading",
      fontSize: [3, 3, 3],
    },
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: ["40em", "56em", "64em"],
}
